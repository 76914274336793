<template>
  <div class="flex border border-light">
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
        'pointer-events-none': value < 1,
      }"
      @click="change(-recommendedQty)"
    >
      <img
        src="/icons/minus.svg"
        class="w-16 h-16 outline outline-8 outline-transparent transition-all
        group-hover:bg-brandLight group-hover:outline-brandLight"
        :class="{
          'opacity-50': value < 1,
        }"
        alt=""
      >
    </button>
    <div>
      <div
        v-if="loading && noInputField"
        class="miniNumber w-32 h-32 desk:h-40 flex items-center justify-center"
      >
        <LoadingIcon />
      </div>
      <div
        v-else-if="noInputField"
        class="miniNumber w-32 py-[10px] desk:py-12 h-32 desk:h-40 type-xs-medium desk:type-sm-medium"
      >
        {{ value }}
      </div>
      <input
        v-else
        :id="id"
        v-model="value"
        type="number"
        class="h-32 min-w-88 w-full px-8 fastOrder"
        :class="{ hideBrowserUpDownButtons: showButtonsOnDesktop }"
        min="1"
        :tabindex="(tabindex * 10) + 1"
        v-bind="combinedAttr"
        :disabled="loading || disabled"
        :readonly="loading || disabled"
        @keydown.enter="emit('enter')"
        @blur="emit('blurred')"
        @keyup="emit('change')"
        @change="emit('change')"
      >
    </div>
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
      }"
      @click="change(recommendedQty)"
    >
      <img
        src="/icons/plus.svg"
        class="w-16 h-16 outline outline-8 outline-transparent transition-all
          group-hover:bg-brandLight group-hover:outline-brandLight"
        alt=""
      >
    </button>
  </div>
</template>

<script setup lang="ts">
/**
 * Usage:
 * Minimal:
 * <TouchNumber v-model="touch1" />
 *
 * Adding any attributes, like min/max as object
 * <TouchNumber
 *    v-model="touch1"
 *    :attr="{ class: 'customClass', max: 100, min: 0, step: 1 }"
 *  />
 *
 *  Setting step to >= 1 will prevent user from pressing .
 *  Setting min to >= 0 will prevent user from pressing -
 */
const value = defineModel<number>({ required: true });
const props = defineProps<{
  tabindex?: number,
  id?: string,
  attr?: {
    [key: string]: string | number;
  },
  disabled?: boolean,
  showButtonsOnDesktop?: boolean;
  noInputField?:boolean;
  loading?: boolean;
  recommendedQty: number;
}>();

const emit = defineEmits<{
  (e: 'enter'): void;
  (e: 'blurred'): void;
  (e: 'change'): void;
}>();

const combinedAttr = computed(()=> {
  let output = {} as {
    [key: string]: string | number;
  };
  if (props.attr) {
    output = { ...props.attr };
  }
  if (props.id) {
    output.id = props.id;
  }
  if (props.tabindex) {
    output.tabindex = (props.tabindex * 10) +1;
  }
  return output;
});

let max = null as number | null;
if (props.attr?.max) {
  max = typeof props.attr.max === 'number' ? props.attr.max : parseFloat(props.attr.max);
}
let min = null as number | null;
if (props.attr?.min) {
  min = typeof props.attr.min === 'number' ? props.attr.min : parseFloat(props.attr.min);
}
let step = null as number | null;
if (props.attr?.step) {
  step = typeof props.attr.step === 'number' ? props.attr.step : parseFloat(props.attr.step);
}

const change = (dir: number) => {
  const newNumber = value.value + dir;
  if (max && newNumber > max) {
    return;
  }
  if (min && newNumber < min) {
    return;
  }

  value.value = newNumber;
  emit('change');
};

</script>

<style scoped lang="postcss">
.miniNumber {
  @apply leading-single rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .miniNumber {
    @apply text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply hidden;
    }
  }
}

@media (pointer: fine) {
  .miniNumber {
    @apply text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
  .touchOnly {
    @apply hidden;
  }
}

.hideBrowserUpDownButtons {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    @apply opacity-100;
  }
}

.fastOrder {
  @apply leading-single rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .fastOrder {
    @apply text-left;
  }
}

@media (pointer: fine) {
  .fastOrder {
    @apply text-left;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
}

</style>
